






















































import { Component, Vue } from "vue-property-decorator";
import admin_registro_llamadaModule from "@/store/modules/Admin/admin_registro_llamada-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { admin_registro_llamada } from "@/shared/dtos/admin/admin_registro_llamada";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class AccesosAppLista extends Vue {
  public llamadas: admin_registro_llamada[] = [];
  public show_dialog_more_options: boolean = false;
  public more_options_for: number = 0;
  public llamada_seleccionada: admin_registro_llamada = new admin_registro_llamada();
  async created() {
    await admin_registro_llamadaModule.getadmin_registro_llamadas();
    this.shwPte();
  }

  get dtsource() {
    return this.llamadas;
  }

  get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "id_nutricionista",
        "Nutricionista",
        datatypes.number,
        false,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "apellidos",
        "Apellidos",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "telefono",
        "Telefono",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "pendiente_llamar",
        "Pendiente",
        datatypes.boolean,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "FechaHora",
        datatypes.datetime,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "accion",
        "Acción",
        datatypes.string,
        false,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "no_llamar",
        "No llamar",
        datatypes.boolean,
        false,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "me_llama",
        "Me llama",
        datatypes.boolean,
        false,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "email",
        "Email",
        datatypes.string,
        false,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "id",
        "ID",
        datatypes.string,
        false,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    opts.push(
      new buttonOptions({
        id: 2,
        title: "Marcar como hecha",
        EventMethod: this.Hecha,
      })
    );
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Editar",
        EventMethod: this.Editar,
      })
    );

    opts.push(
      new buttonOptions({
        id: 3,
        title: "Duplicar",
        EventMethod: this.Duplicar,
      })
    );
    opts.push(
      new buttonOptions({
        id: 4,
        title: "Eliminar",
        EventMethod: this.Eliminar,
      })
    );
    return opts;
  }

  public Editar() {
    this.toLlamada({ key: this.more_options_for });
  }

  public async GenProxLlam() {
    await admin_registro_llamadaModule.getGenerarNuevasLLamadas();
  }

  public async Hecha() {
    this.llamada_seleccionada.pendiente_llamar = false;
    await admin_registro_llamadaModule.modificaradmin_registro_llamada(
      this.llamada_seleccionada
    );
    this.show_dialog_more_options = false;
    this.shwPte();
  }
  public async Duplicar() {
    this.llamada_seleccionada.id = 0;
    await admin_registro_llamadaModule.guardaradmin_registro_llamada(
      this.llamada_seleccionada
    );
    this.show_dialog_more_options = false;
    await admin_registro_llamadaModule.getadmin_registro_llamadas();
    this.shwPte();
  }
  public async Eliminar() {
    await admin_registro_llamadaModule.eliminaradmin_registro_llamada(
      this.llamada_seleccionada
    );
    this.show_dialog_more_options = false;
    await admin_registro_llamadaModule.getadmin_registro_llamadas();
    this.shwPte();
  }

  public async MoreOptions(id: any) {
    if (id <= 0) {
      return;
    }
    this.more_options_for = id;
    let ss = this.dtsource.find((x) => x.id === id);
    if (ss !== undefined) {
      this.llamada_seleccionada = ss;
    }
    this.show_dialog_more_options = true;
  }

  public shwAll() {
    this.llamadas =
      admin_registro_llamadaModule.admin_registro_llamadas; /*.filter(
      (x) => x.pendiente_llamar === true
    );*/
  }
  public shwPte() {
    this.llamadas = admin_registro_llamadaModule.admin_registro_llamadas.filter(
      (x) => x.pendiente_llamar === true
    );
  }
  public shwHechas() {
    this.llamadas = admin_registro_llamadaModule.admin_registro_llamadas.filter(
      (x) => x.pendiente_llamar === false
    );
  }
  public toLlamada(e: any) {
    this.$router.push({
      name: RouterNames.adminllamada,
      params: { id: e.key.toString() },
    });
  }
  public add_nueva() {
    this.$router.push({
      name: RouterNames.adminllamada,
      params: { id: "-1" },
    });
  }
}
